<template>
  <div class="cityList app-contianer">
    <div class="hotCity-box">
      <p class="f40">热门城市</p>
      <ul>
        <li v-for="(item, index) in hotCity" :key="index" @click="changeCity(item)">{{item.name}}</li>
      </ul>
    </div>
    <van-index-bar highlight-color="#2876dd">
      <div v-for="(province, index) in mapJson" :key="index">
        <van-index-anchor :index="province.title" />
        <van-cell :title="item.name" v-for="(item,i) in province.lists" :key="i" @click="changeCity(item)" />
      </div>

    </van-index-bar>
  </div>
</template>

<script>
import { cityMap } from '../utils/cityMap'
import { mapState } from 'vuex'

export default {
  name: 'CityList',
  data() {
    return {
      indexList: [],
      mapJson: cityMap,
      hotCity: [],
    }
  },
  computed: {
    ...mapState('user', ['mapKey']),
  },
  created() {
    // console.log(cityMap)
    this.setHotCity()
  },
  methods: {
    changeCity(item) {
      this.$jsonp('https://apis.map.qq.com/ws/geocoder/v1/?', {
        location: `${item.lat},${item.log}`,
        output: 'jsonp', // output必须jsonp   不然会超时
        key: this.mapKey,
      }).then((res) => {
        if (res.result) {
          location.longitude = res.result.location.lng
          location.latitude = res.result.location.lat
          this.$store.commit('user/SET_LOCATION', location)
          this.$store.commit('user/SET_CITYNAME', res.result.ad_info.city)
          this.$store.commit(
            'user/SET_ADDRESS',
            res.result.formatted_addresses.recommend
          )

          this.$router.push({ path: '/' })
        }
      })
    },
    setHotCity() {
      this.hotCity = [
        {
          name: '上海',
          log: '121.48',
          lat: '31.22',
        },
        {
          name: '广州',
          log: '113.23',
          lat: '23.16',
        },
        {
          name: '深圳',
          log: '114.07',
          lat: '22.62',
        },
        {
          name: '北京',
          log: '116.46',
          lat: '39.92',
        },
        {
          name: '成都',
          log: '104.06',
          lat: '30.67',
        },
        {
          name: '杭州',
          log: '120.19',
          lat: '30.26',
        },
        {
          name: '重庆',
          log: '106.54',
          lat: '29.59',
        },
        {
          name: '东莞',
          log: '113.75',
          lat: '23.04',
        },
        {
          name: '南京',
          log: '118.78',
          lat: '32.04',
        },
      ]
    },
  },
}
</script>

<style lang='less' scoped>
.hotCity-box {
  padding: 4vw;
  border-bottom: 1px solid #ebedf0;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: 30%;
      text-align: center;
      background: #fff;
      border-radius: 1vw;
      padding: 2vw 0;
      font-size: 3.6vw;
      margin-top: 3vw;
      border: 1px solid #e6e6e6;
    }
  }
}
/deep/ .van-cell {
  font-size: 3.6vw;
}
/deep/ .van-index-anchor {
  font-size: 3.6vw;
  background: #f1f5f8;
}
/deep/ .van-index-bar__index {
  font-size: 3.2vw;
  line-height: 4vw;
}

/deep/ .van-index-bar__sidebar {
  top: 60%;
}
</style>