export const cityMap = [{
  "title": "A",
  "lists": [{
    "name": "阿坝",
    "log": "101.72",
    "lat": "31.93"
  }, {
    "name": "安康",
    "log": "109.02",
    "lat": "32.7"
  }, {
    "name": "安庆",
    "log": "117.03",
    "lat": "30.52"
  }, {
    "name": "鞍山",
    "log": "122.85",
    "lat": "41.12"
  }, {
    "name": "安顺",
    "log": "105.92",
    "lat": "26.25"
  }, {
    "name": "安阳",
    "log": "114.35",
    "lat": "36.1"
  }, {
    "name": "澳门",
    "log": "113.33",
    "lat": "22.13"
  }]
}, {
  "title": "B",
  "lists": [{
    "name": "北京",
    "log": "116.46",
    "lat": "39.92"
  }, {
    "name": "保定",
    "log": "115.48",
    "lat": "38.85"
  }, {
    "name": "宝鸡",
    "log": "107.15",
    "lat": "34.38"
  }, {
    "name": "包头",
    "log": "110",
    "lat": "40.58"
  }, {
    "name": "巴中",
    "log": "106.73",
    "lat": "31.86"
  }, {
    "name": "北海",
    "log": "109.12",
    "lat": "21.49"
  }, {
    "name": "蚌埠",
    "log": "117.34",
    "lat": "32.93"
  }, {
    "name": "本溪",
    "log": "123.73",
    "lat": "41.3"
  }, {
    "name": "毕节",
    "log": "105.29",
    "lat": "27.32"
  }, {
    "name": "滨州",
    "log": "118.03",
    "lat": "37.36"
  }, {
    "name": "百色",
    "log": "106.62",
    "lat": "23.91"
  }]
}, {
  "title": "C",
  "lists": [{
    "name": "重庆",
    "log": "106.54",
    "lat": "29.59"
  }, {
    "name": "成都",
    "log": "104.06",
    "lat": "30.67"
  }, {
    "name": "长沙",
    "log": "113",
    "lat": "28.21"
  }, {
    "name": "长春",
    "log": "125.35",
    "lat": "43.88"
  }, {
    "name": "沧州",
    "log": "116.83",
    "lat": "38.33"
  }, {
    "name": "常德",
    "log": "111.69",
    "lat": "29.05"
  }, {
    "name": "长治",
    "log": "113.08",
    "lat": "36.18"
  }, {
    "name": "常州",
    "log": "119.95",
    "lat": "31.79"
  }, {
    "name": "巢湖",
    "log": "117.87",
    "lat": "31.62"
  }, {
    "name": "潮州",
    "log": "116.63",
    "lat": "23.68"
  }, {
    "name": "承德",
    "log": "117.93",
    "lat": "40.97"
  }, {
    "name": "郴州",
    "log": "113",
    "lat": "25.79"
  }, {
    "name": "赤峰",
    "log": "118.87",
    "lat": "42.28"
  }, {
    "name": "崇左",
    "log": "107.37",
    "lat": "22.42"
  }, {
    "name": "楚雄",
    "log": "101.54",
    "lat": "25.01"
  }, {
    "name": "滁州",
    "log": "118.31",
    "lat": "32.33"
  }, {
    "name": "朝阳",
    "log": "120.42",
    "lat": "41.58"
  }]
}, {
  "title": "D",
  "lists": [{
    "name": "大连",
    "log": "121.62",
    "lat": "38.92"
  }, {
    "name": "东莞",
    "log": "113.75",
    "lat": "23.04"
  }, {
    "name": "大理",
    "log": "100.19",
    "lat": "25.69"
  }, {
    "name": "丹东",
    "log": "124.37",
    "lat": "40.13"
  }, {
    "name": "大庆",
    "log": "125.03",
    "lat": "46.58"
  }, {
    "name": "大同",
    "log": "113.3",
    "lat": "40.12"
  }, {
    "name": "德阳",
    "log": "104.37",
    "lat": "31.13"
  }, {
    "name": "德州",
    "log": "116.29",
    "lat": "37.45"
  }, {
    "name": "定西",
    "log": "104.57",
    "lat": "35.57"
  }, {
    "name": "东营",
    "log": "118.49",
    "lat": "37.46"
  }]
}, {
  "title": "E",
  "lists": []
}, {
  "title": "F",
  "lists": [{
    "name": "福州",
    "log": "119.3",
    "lat": "26.08"
  }, {
    "name": "佛山",
    "log": "113.11",
    "lat": "23.05"
  }, {
    "name": "抚顺",
    "log": "123.97",
    "lat": "41.97"
  }, {
    "name": "抚州",
    "log": "116.34",
    "lat": "28"
  }, {
    "name": "阜新",
    "log": "121.65",
    "lat": "42"
  }, {
    "name": "阜阳",
    "log": "115.81",
    "lat": "32.89"
  }]
}, {
  "title": "G",
  "lists": [{
    "name": "广州",
    "log": "113.23",
    "lat": "23.16"
  }, {
    "name": "桂林",
    "log": "110.28",
    "lat": "25.29"
  }, {
    "name": "贵阳",
    "log": "106.71",
    "lat": "26.57"
  }, {
    "name": "甘南",
    "log": "123.48",
    "lat": "47.9"
  }, {
    "name": "赣州",
    "log": "114.92",
    "lat": "25.85"
  }, {
    "name": "甘孜",
    "log": "99.96",
    "lat": "31.64"
  }, {
    "name": "广安",
    "log": "106.61",
    "lat": "30.48"
  }]
}, {
  "title": "H",
  "lists": [{
    "name": "杭州",
    "log": "120.19",
    "lat": "30.26"
  }, {
    "name": "哈尔滨",
    "log": "126.63",
    "lat": "45.75"
  }, {
    "name": "合肥",
    "log": "117.27",
    "lat": "31.86"
  }, {
    "name": "海口",
    "log": "110.35",
    "lat": "20.02"
  }, {
    "name": "呼和浩特",
    "log": "111.65",
    "lat": "40.82"
  }, {
    "name": "邯郸",
    "log": "114.47",
    "lat": "36.6"
  }, {
    "name": "汉中",
    "log": "108.04",
    "lat": "33.07"
  }, {
    "name": "鹤壁",
    "log": "114.17",
    "lat": "35.9"
  }, {
    "name": "河池",
    "log": "108.06",
    "lat": "24.7"
  }, {
    "name": "鹤岗",
    "log": "130.3",
    "lat": "47.33"
  }, {
    "name": "黑河",
    "log": "127.53",
    "lat": "50.22"
  }, {
    "name": "衡水",
    "log": "115.72",
    "lat": "37.72"
  }, {
    "name": "衡阳",
    "log": "112.61",
    "lat": "26.89"
  }, {
    "name": "河源",
    "log": "114.68",
    "lat": "23.73"
  }, {
    "name": "红河",
    "log": "102.42",
    "lat": "23.35"
  }, {
    "name": "淮安",
    "log": "119.15",
    "lat": "33.5"
  }, {
    "name": "淮北",
    "log": "116.77",
    "lat": "33.97"
  }, {
    "name": "怀化",
    "log": "109.95",
    "lat": "27.52"
  }, {
    "name": "淮南",
    "log": "116.98",
    "lat": "32.62"
  }, {
    "name": "黄冈",
    "log": "114.87",
    "lat": "30.44"
  }, {
    "name": "黄石",
    "log": "115.09",
    "lat": "30.2"
  }, {
    "name": "惠州",
    "log": "114.4",
    "lat": "23.09"
  }, {
    "name": "湖州",
    "log": "120.1",
    "lat": "30.86"
  }]
}, {
  "title": "J",
  "lists": [{
    "name": "济南",
    "log": "117",
    "lat": "36.65"
  }, {
    "name": "佳木斯",
    "log": "130.35",
    "lat": "46.83"
  }, {
    "name": "吉安",
    "log": "114.97",
    "lat": "27.12"
  }, {
    "name": "江门",
    "log": "113.06",
    "lat": "22.61"
  }, {
    "name": "焦作",
    "log": "113.21",
    "lat": "35.24"
  }, {
    "name": "嘉兴",
    "log": "120.76",
    "lat": "30.77"
  }, {
    "name": "揭阳",
    "log": "116.35",
    "lat": "23.55"
  }, {
    "name": "吉林",
    "log": "126.57",
    "lat": "43.87"
  }, {
    "name": "晋城",
    "log": "112.83",
    "lat": "35.52"
  }, {
    "name": "景德镇",
    "log": "117.22",
    "lat": "29.3"
  }, {
    "name": "荆门",
    "log": "112.19",
    "lat": "31.02"
  }, {
    "name": "金华",
    "log": "119.64",
    "lat": "29.12"
  }, {
    "name": "济宁",
    "log": "116.59",
    "lat": "35.38"
  }, {
    "name": "锦州",
    "log": "121.15",
    "lat": "41.13"
  }, {
    "name": "九江",
    "log": "115.97",
    "lat": "29.71"
  }, {
    "name": "酒泉",
    "log": "98.5",
    "lat": "39.71"
  }]
}, {
  "title": "K",
  "lists": [{
    "name": "昆明",
    "log": "102.73",
    "lat": "25.04"
  }, {
    "name": "开封",
    "log": "114.35",
    "lat": "34.79"
  }]
}, {
  "title": "L",
  "lists": [{
    "name": "兰州",
    "log": "103.73",
    "lat": "36.03"
  }, {
    "name": "拉萨",
    "log": "91.11",
    "lat": "29.97"
  }, {
    "name": "来宾",
    "log": "109.24",
    "lat": "23.76"
  }, {
    "name": "莱芜",
    "log": "117.67",
    "lat": "36.19"
  }, {
    "name": "廊坊",
    "log": "116.7",
    "lat": "39.53"
  }, {
    "name": "连云港",
    "log": "119.16",
    "lat": "34.59"
  }, {
    "name": "聊城",
    "log": "115.97",
    "lat": "36.45"
  }, {
    "name": "辽阳",
    "log": "123.17",
    "lat": "41.28"
  }, {
    "name": "辽源",
    "log": "125.15",
    "lat": "42.97"
  }, {
    "name": "丽江",
    "log": "100.25",
    "lat": "26.86"
  }, {
    "name": "临汾",
    "log": "111.5",
    "lat": "36.08"
  }, {
    "name": "临夏",
    "log": "103.22",
    "lat": "35.62"
  }, {
    "name": "临沂",
    "log": "118.35",
    "lat": "35.05"
  }, {
    "name": "林芝",
    "log": "94.25",
    "lat": "29.59"
  }, {
    "name": "丽水",
    "log": "119.92",
    "lat": "28.45"
  }, {
    "name": "六安",
    "log": "116.49",
    "lat": "31.73"
  }, {
    "name": "六盘水",
    "log": "104.82",
    "lat": "26.58"
  }, {
    "name": "柳州",
    "log": "109.4",
    "lat": "24.33"
  }, {
    "name": "龙岩",
    "log": "117.01",
    "lat": "25.12"
  }, {
    "name": "娄底",
    "log": "111.96",
    "lat": "27.71"
  }, {
    "name": "漯河",
    "log": "114.02",
    "lat": "33.56"
  }, {
    "name": "洛阳",
    "log": "112.44",
    "lat": "34.7"
  }, {
    "name": "泸州",
    "log": "105.39",
    "lat": "28.91"
  }]
}, {
  "title": "M",
  "lists": [{
    "name": "马鞍山",
    "log": "118.48",
    "lat": "31.56"
  }, {
    "name": "茂名",
    "log": "110.88",
    "lat": "21.68"
  }, {
    "name": "梅州",
    "log": "116.1",
    "lat": "24.55"
  }, {
    "name": "绵阳",
    "log": "104.73",
    "lat": "31.48"
  }, {
    "name": "牡丹江",
    "log": "129.58",
    "lat": "44.6"
  }]
}, {
  "title": "N",
  "lists": [{
    "name": "南京",
    "log": "118.78",
    "lat": "32.04"
  }, {
    "name": "南昌",
    "log": "115.89",
    "lat": "28.68"
  }, {
    "name": "南宁",
    "log": "108.33",
    "lat": "22.84"
  }, {
    "name": "宁波",
    "log": "121.56",
    "lat": "29.86"
  }, {
    "name": "南平",
    "log": "118.16",
    "lat": "26.65"
  }, {
    "name": "南通",
    "log": "120.86",
    "lat": "32.01"
  }, {
    "name": "南阳",
    "log": "112.53",
    "lat": "33.01"
  }, {
    "name": "那曲",
    "log": "92.1",
    "lat": "31.47"
  }, {
    "name": "内江",
    "log": "105.04",
    "lat": "29.59"
  }, {
    "name": "宁德",
    "log": "119.52",
    "lat": "26.65"
  }]
}, {
  "title": "P",
  "lists": [{
    "name": "平顶山",
    "log": "113.29",
    "lat": "33.75"
  }, {
    "name": "平凉",
    "log": "106.68",
    "lat": "35.51"
  }, {
    "name": "萍乡",
    "log": "113.85",
    "lat": "27.6"
  }, {
    "name": "莆田",
    "log": "119",
    "lat": "25.44"
  }, {
    "name": "濮阳",
    "log": "114.98",
    "lat": "35.71"
  }]
}, {
  "title": "Q",
  "lists": [{
    "name": "青岛",
    "log": "120.33",
    "lat": "36.07"
  }, {
    "name": "庆阳",
    "log": "107.88",
    "lat": "36.03"
  }, {
    "name": "清远",
    "log": "113.01",
    "lat": "23.7"
  }, {
    "name": "秦皇岛",
    "log": "119.57",
    "lat": "39.95"
  }, {
    "name": "钦州",
    "log": "108.61",
    "lat": "21.96"
  }, {
    "name": "齐齐哈尔",
    "log": "123.97",
    "lat": "47.33"
  }, {
    "name": "泉州",
    "log": "118.58",
    "lat": "24.93"
  }, {
    "name": "曲靖",
    "log": "103.79",
    "lat": "25.51"
  }, {
    "name": "衢州",
    "log": "118.88",
    "lat": "28.97"
  }]
}, {
  "title": "R",
  "lists": [{
    "name": "日喀则",
    "log": "88.82",
    "lat": "29.28"
  }, {
    "name": "日照",
    "log": "119.46",
    "lat": "35.42"
  }]
}, {
  "title": "S",
  "lists": [{
    "name": "上海",
    "log": "121.48",
    "lat": "31.22"
  }, {
    "name": "深圳",
    "log": "114.07",
    "lat": "22.62"
  }, {
    "name": "苏州",
    "log": "120.62",
    "lat": "31.32"
  }, {
    "name": "沈阳",
    "log": "123.38",
    "lat": "41.8"
  }, {
    "name": "石家庄",
    "log": "114.48",
    "lat": "38.03"
  }, {
    "name": "三门峡",
    "log": "111.19",
    "lat": "34.76"
  }, {
    "name": "三明",
    "log": "117.61",
    "lat": "26.23"
  }, {
    "name": "商丘",
    "log": "115.65",
    "lat": "34.44"
  }, {
    "name": "上饶",
    "log": "117.97",
    "lat": "28.47"
  }, {
    "name": "汕头",
    "log": "116.69",
    "lat": "23.39"
  }, {
    "name": "韶关",
    "log": "113.62",
    "lat": "24.84"
  }, {
    "name": "绍兴",
    "log": "120.58",
    "lat": "30.01"
  }, {
    "name": "邵阳",
    "log": "111.5",
    "lat": "27.22"
  }, {
    "name": "十堰",
    "log": "110.79",
    "lat": "32.65"
  }, {
    "name": "四平",
    "log": "124.37",
    "lat": "43.17"
  }, {
    "name": "绥化",
    "log": "127",
    "lat": "46.63"
  }, {
    "name": "遂宁",
    "log": "105.58",
    "lat": "30.52"
  }, {
    "name": "宿迁",
    "log": "118.3",
    "lat": "33.96"
  }, {
    "name": "宿州",
    "log": "116.97",
    "lat": "33.63"
  }]
}, {
  "title": "T",
  "lists": [{
    "name": "天津",
    "log": "117.2",
    "lat": "39.13"
  }, {
    "name": "太原",
    "log": "112.53",
    "lat": "37.87"
  }, {
    "name": "泰安",
    "log": "117.13",
    "lat": "36.18"
  }, {
    "name": "泰州",
    "log": "119.9",
    "lat": "32.49"
  }, {
    "name": "唐山",
    "log": "118.02",
    "lat": "39.63"
  }, {
    "name": "天水",
    "log": "105.69",
    "lat": "34.6"
  }, {
    "name": "铁岭",
    "log": "123.85",
    "lat": "42.32"
  }, {
    "name": "铜川",
    "log": "109.11",
    "lat": "35.09"
  }, {
    "name": "通化",
    "log": "125.92",
    "lat": "41.49"
  }, {
    "name": "通辽",
    "log": "122.28",
    "lat": "43.63"
  }, {
    "name": "铜陵",
    "log": "117.82",
    "lat": "30.93"
  }, {
    "name": "铜仁",
    "log": "109.21",
    "lat": "27.73"
  }, {
    "name": "台湾",
    "log": "121.5",
    "lat": "25.14"
  }]
}, {
  "title": "W",
  "lists": [{
    "name": "武汉",
    "log": "114.31",
    "lat": "30.52"
  }, {
    "name": "乌鲁木齐",
    "log": "87.68",
    "lat": "43.77"
  }, {
    "name": "无锡",
    "log": "120.29",
    "lat": "31.59"
  }, {
    "name": "威海",
    "log": "122.1",
    "lat": "37.5"
  }, {
    "name": "潍坊",
    "log": "119.1",
    "lat": "36.62"
  }, {
    "name": "文山",
    "log": "104.24",
    "lat": "23.37"
  }, {
    "name": "温州",
    "log": "120.65",
    "lat": "28.01"
  }, {
    "name": "乌海",
    "log": "106.82",
    "lat": "39.67"
  }, {
    "name": "芜湖",
    "log": "118.38",
    "lat": "31.33"
  }, {
    "name": "武威",
    "log": "102.61",
    "lat": "37.94"
  }, {
    "name": "梧州",
    "log": "111.34",
    "lat": "23.51"
  }]
}, {
  "title": "X",
  "lists": [{
    "name": "厦门",
    "log": "118.1",
    "lat": "24.46"
  }, {
    "name": "西安",
    "log": "108.95",
    "lat": "34.27"
  }, {
    "name": "西宁",
    "log": "101.74",
    "lat": "36.56"
  }, {
    "name": "襄樊",
    "log": "112.14",
    "lat": "30.02"
  }, {
    "name": "湘潭",
    "log": "112.91",
    "lat": "27.87"
  }, {
    "name": "咸宁",
    "log": "114.28",
    "lat": "29.87"
  }, {
    "name": "咸阳",
    "log": "108.72",
    "lat": "34.36"
  }, {
    "name": "孝感",
    "log": "113.91",
    "lat": "31.92"
  }, {
    "name": "邢台",
    "log": "114.48",
    "lat": "37.05"
  }, {
    "name": "新乡",
    "log": "113.85",
    "lat": "35.31"
  }, {
    "name": "信阳",
    "log": "114.08",
    "lat": "32.13"
  }, {
    "name": "新余",
    "log": "114.92",
    "lat": "27.81"
  }, {
    "name": "宣城",
    "log": "118.73",
    "lat": "31.95"
  }, {
    "name": "许昌",
    "log": "113.81",
    "lat": "34.02"
  }, {
    "name": "徐州",
    "log": "117.2",
    "lat": "34.26"
  }, {
    "name": "香港",
    "log": "114.1",
    "lat": "22.2"
  }, {
    "name": "兴安",
    "log": "110.66",
    "lat": "25.6"
  }]
}, {
  "title": "Y",
  "lists": [{
    "name": "银川",
    "log": "106.27",
    "lat": "38.47"
  }, {
    "name": "雅安",
    "log": "102.97",
    "lat": "29.97"
  }, {
    "name": "延安",
    "log": "109.47",
    "lat": "36.6"
  }, {
    "name": "盐城",
    "log": "120.13",
    "lat": "33.38"
  }, {
    "name": "阳江",
    "log": "111.95",
    "lat": "21.85"
  }, {
    "name": "阳泉",
    "log": "113.57",
    "lat": "37.85"
  }, {
    "name": "扬州",
    "log": "119.42",
    "lat": "32.39"
  }, {
    "name": "烟台",
    "log": "121.39",
    "lat": "37.52"
  }, {
    "name": "宜宾",
    "log": "104.56",
    "lat": "29.77"
  }, {
    "name": "宜昌",
    "log": "111.3",
    "lat": "30.7"
  }, {
    "name": "宜春",
    "log": "114.38",
    "lat": "27.81"
  }, {
    "name": "营口",
    "log": "122.18",
    "lat": "40.65"
  }, {
    "name": "益阳",
    "log": "112.33",
    "lat": "28.6"
  }, {
    "name": "永州",
    "log": "111.63",
    "lat": "26.22"
  }, {
    "name": "岳阳",
    "log": "113.09",
    "lat": "29.37"
  }, {
    "name": "榆林",
    "log": "109.77",
    "lat": "38.3"
  }, {
    "name": "运城",
    "log": "110.97",
    "lat": "35.03"
  }, {
    "name": "云浮",
    "log": "112.02",
    "lat": "22.93"
  }, {
    "name": "玉树",
    "log": "96.97",
    "lat": "33.03"
  }, {
    "name": "玉溪",
    "log": "102.52",
    "lat": "24.35"
  }, {
    "name": "玉林",
    "log": "110.14",
    "lat": "22.64"
  }]
}, {
  "title": "Z",
  "lists": [{
    "name": "枣庄",
    "log": "117.57",
    "lat": "34.86"
  }, {
    "name": "扎鲁特旗",
    "log": "120.87",
    "lat": "44.55"
  }, {
    "name": "张家口",
    "log": "114.87",
    "lat": "40.82"
  }, {
    "name": "漳县",
    "log": "104.48",
    "lat": "34.87"
  }, {
    "name": "张掖",
    "log": "100.46",
    "lat": "38.93"
  }, {
    "name": "漳州",
    "log": "117.35",
    "lat": "24.52"
  }, {
    "name": "肇庆",
    "log": "112.44",
    "lat": "23.05"
  }, {
    "name": "昭通",
    "log": "103.7",
    "lat": "29.32"
  }, {
    "name": "赵县",
    "log": "114.78",
    "lat": "37.76"
  }, {
    "name": "正蓝旗",
    "log": "116.02",
    "lat": "42.25"
  }, {
    "name": "正镶白旗",
    "log": "115",
    "lat": "42.32"
  }, {
    "name": "郑州",
    "log": "113.65",
    "lat": "34.76"
  }, {
    "name": "镇江",
    "log": "119.44",
    "lat": "32.2"
  }, {
    "name": "中山",
    "log": "113.38",
    "lat": "22.52"
  }, {
    "name": "中卫",
    "log": "105.18",
    "lat": "37.51"
  }, {
    "name": "周口",
    "log": "114.63",
    "lat": "33.63"
  }, {
    "name": "珠海",
    "log": "113.52",
    "lat": "22.3"
  }, {
    "name": "驻马店",
    "log": "114.02",
    "lat": "32.98"
  }, {
    "name": "准格尔旗",
    "log": "111.13",
    "lat": "39.68"
  }, {
    "name": "卓尼",
    "log": "103.54",
    "lat": "34.61"
  }, {
    "name": "株洲",
    "log": "113.16",
    "lat": "27.83"
  }, {
    "name": "淄博",
    "log": "118.05",
    "lat": "36.78"
  }, {
    "name": "资阳",
    "log": "104.6",
    "lat": "30.19"
  }]
}]